import React from 'react'
import Feature from './Feature'
import About from './About';
import Fact from './Fact';
import Service from './Service';
import Project from './Project';
import Testimonial from './Testimonial';
import Team from './Team';
import HomeCover from './HomeCover';

function Home() {

    // ****** for feature ******
    const feature = [
        {
            icon: "fa fa-3x fa-mail-bulk",
            title: "Digital Marketing",
            para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
        },
        {
            icon: "fa fa-3x fa-search",
            title: "SEO & Backlinks",
            para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
        },
        {
            icon: "fa fa-3x fa-laptop-code",
            title: "Design & Development",
            para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
        }
    ]

    // ****** for service ******
    const service = [
        {
            icon: "fa fa-search fa-2x",
            title: "SEO Optimization",
            para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
            link: "/",
        },
        {
            icon: "fa fa-laptop-code fa-2x",
            title: "Web Design",
            para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
            link: "/",
        },
        {
            icon: "fab fa-facebook-f fa-2x",
            title: "Social Media Marketing",
            para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
            link: "/",
        },
        {
            icon: "fa fa-mail-bulk fa-2x",
            title: "SEO Optimization",
            para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
            link: "/",
        },
        {
            icon: "fa fa-mail-bulk fa-2x",
            title: "Email Marketing",
            para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
            link: "/",
        },
        {
            icon: "fa fa-thumbs-up fa-2x",
            title: "PPC Advertising",
            para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
            link: "/",
        },
    ]

    // ****** for product ******
    const product = [
        {
            filter:"first",
            image:"img/portfolio-1.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        },
        {
            filter:"second",
            image:"img/portfolio-2.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        },
        {
            filter:"second",
            image:"img/portfolio-3.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        },
        {
            filter:"first",
            image:"img/portfolio-4.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        },
        {
            filter:"second",
            image:"img/portfolio-5.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        },
        {
            filter:"first",
            image:"img/portfolio-6.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        }
    ]

    // ****** for testimonial ******
    const testimonial = [
        {
            img:"img/testimonial-1.jpg",
            name:"Client Name",
            profession:"Profession",
            title:"Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed stet lorem sit clita duo justo.",
        },
        {
            img:"img/testimonial-2.jpg",
            name:"Client Name",
            profession:"Profession",
            title:"Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed stet lorem sit clita duo justo.",
        },
        {
            img:"img/testimonial-3.jpg",
            name:"Client Name",
            profession:"Profession",
            title:"Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed stet lorem sit clita duo justo.",
        },
    ]

    // ****** for testimonial ******
    const team = [
        {
            img:"img/team-1.jpg",
            name:"John Doe",
            profession:"CEO & Founder",
            fb:"",
            tw:"",
            insta:"",
            linkedln:"",
        },
        {
            img:"img/team-2.jpg",
            name:"Jessica Brown",
            profession:"Web Designer",
            fb:"",
            tw:"",
            insta:"",
            linkedln:"",
        },
        {
            img:"img/team-3.jpg",
            name:"Tony Johnson",
            profession:"SEO Expert",
            fb:"",
            tw:"",
            insta:"",
            linkedln:"",
        },
    ]


    return (
        <>
            <HomeCover/>
            <Feature data={feature}/>
            <About/>
            <Fact/>
            <Service data={service}/>
            <Project data={product}/>
            <Testimonial data={testimonial}/>
            <Team data={team}/>
        </>
    )
}

export default Home
