import React from 'react'
import About from '../About'
import CommonCover from '../CommonCover'


function AboutPg() {
    return (
        <>
            <CommonCover breadcrumb="About Us"/>
            <About/>
        </>
    )
}

export default AboutPg
