import React from 'react'
import CommonCover from '../CommonCover'
import Testimonial from '../Testimonial'

function TestimonialPg() {

    // ****** for testimonial ******
    const testimonial = [
        {
            img: "img/testimonial-1.jpg",
            name: "Client Name",
            profession: "Profession",
            title: "Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed stet lorem sit clita duo justo.",
        },
        {
            img: "img/testimonial-2.jpg",
            name: "Client Name",
            profession: "Profession",
            title: "Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed stet lorem sit clita duo justo.",
        },
        {
            img: "img/testimonial-3.jpg",
            name: "Client Name",
            profession: "Profession",
            title: "Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed stet lorem sit clita duo justo.",
        },
    ]
    
    return (
        <>
            <CommonCover breadcrumb="Testimonial" />
            <Testimonial data={testimonial} />

        </>
    )
}

export default TestimonialPg
