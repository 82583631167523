import React from 'react'
import CommonCover from '../CommonCover'
import Project from '../Project'
function ProjectPg() {
     // ****** for product ******
     const product = [
        {
            filter:"first",
            image:"img/portfolio-1.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        },
        {
            filter:"second",
            image:"img/portfolio-2.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        },
        {
            filter:"second",
            image:"img/portfolio-3.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        },
        {
            filter:"first",
            image:"img/portfolio-4.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        },
        {
            filter:"second",
            image:"img/portfolio-5.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        },
        {
            filter:"first",
            image:"img/portfolio-6.jpg",
            sub_title:"UI / UX Design",
            title:"Digital Agency Website Design And Development",
            link:"/",
        }
    ]
    
    return (
        <>
            <CommonCover breadcrumb="Project" />
            <Project data={product}/>
        </>
    )
}

export default ProjectPg
