import React from 'react'

function FormField(props) {
    return (
        <>
            <div className={props.col}>
                <div className="form-floating">
                    <input type={props.type} className="form-control" id={props.id} placeholder={props.label} name={props.name} value={props.value} onChange={props.eventHandler}/>
                    <label htmlFor={props.id}>{props.label}</label>
                </div>
            </div>
        </>
    )
}

export default FormField
