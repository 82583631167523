import React from 'react'
import CommonCover from '../CommonCover'
import Service from '../Service'
import Testimonial from '../Testimonial'


function ServicePg() {

        // ****** for service ******
        const service = [
            {
                icon: "fa fa-search fa-2x",
                title: "SEO Optimization",
                para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
                link: "/",
            },
            {
                icon: "fa fa-laptop-code fa-2x",
                title: "Web Design",
                para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
                link: "/",
            },
            {
                icon: "fab fa-facebook-f fa-2x",
                title: "Social Media Marketing",
                para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
                link: "/",
            },
            {
                icon: "fa fa-mail-bulk fa-2x",
                title: "SEO Optimization",
                para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
                link: "/",
            },
            {
                icon: "fa fa-mail-bulk fa-2x",
                title: "Email Marketing",
                para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
                link: "/",
            },
            {
                icon: "fa fa-thumbs-up fa-2x",
                title: "PPC Advertising",
                para: "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
                link: "/",
            },
        ]
    

    // ****** for testimonial ******
    const testimonial = [
        {
            img: "img/testimonial-1.jpg",
            name: "Client Name",
            profession: "Profession",
            title: "Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed stet lorem sit clita duo justo.",
        },
        {
            img: "img/testimonial-2.jpg",
            name: "Client Name",
            profession: "Profession",
            title: "Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed stet lorem sit clita duo justo.",
        },
        {
            img: "img/testimonial-3.jpg",
            name: "Client Name",
            profession: "Profession",
            title: "Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed stet lorem sit clita duo justo.",
        },
    ]
    
    return (
        <>
            <CommonCover breadcrumb="Service" />
            <Service data={service} />
            <Testimonial data={testimonial} />

        </>
    )
}

export default ServicePg
