import React from 'react'

function Feature(props) {
    return (
        <>
            <div className="container-xxl py-5">
                <div className="container py-5 px-lg-5">
                    <div className="row g-4">

                        {props.data.map((show)=>
                            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="feature-item bg-light rounded text-center p-4">
                                    <i className={`${show.icon} text-primary mb-4`}></i>
                                    <h5 className="mb-3">{show.title}</h5>
                                    <p className="m-0">{show.para}</p>
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Feature
