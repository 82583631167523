import React from 'react'
import CommonCover from '../CommonCover'
import Team from '../Team'

function OurTeamPg() {
    // ****** for testimonial ******
    const team = [
        {
            img:"img/team-1.jpg",
            name:"John Doe",
            profession:"CEO & Founder",
            fb:"",
            tw:"",
            insta:"",
            linkedln:"",
        },
        {
            img:"img/team-2.jpg",
            name:"Jessica Brown",
            profession:"Web Designer",
            fb:"",
            tw:"",
            insta:"",
            linkedln:"",
        },
        {
            img:"img/team-3.jpg",
            name:"Tony Johnson",
            profession:"SEO Expert",
            fb:"",
            tw:"",
            insta:"",
            linkedln:"",
        },
    ]
    
    return (
        <>
            <CommonCover breadcrumb="Our Team" />
            <Team data={team}/>
        </>
    )
}

export default OurTeamPg
