import React from 'react'

function Testimonial(props) {
    return (
        <>
            <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5 px-lg-5">
                    <p className="section-title text-secondary justify-content-center"><span></span>Testimonial<span></span></p>
                    <h1 className="text-center mb-5">What Say Our Clients!</h1>
                    <div className="owl-carousel testimonial-carousel">

                        {props.data.map((show)=>
                            <div className="testimonial-item bg-light rounded my-4">
                                <p className="fs-5"><i className="fa fa-quote-left fa-4x text-primary mt-n4 me-3"></i>{show.title}</p>
                                <div className="d-flex align-items-center">
                                    <img className="img-fluid flex-shrink-0 rounded-circle" src={show.img} alt='test1' style={{width: "65px", height: "65px"}} />
                                    <div className="ps-4">
                                        <h5 className="mb-1">{show.name}</h5>
                                        <span>{show.profession}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial
