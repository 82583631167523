import React from 'react'
import { Link } from 'react-router-dom'

function Project(props) {
    return (
        <>
            <div className="container-xxl py-5">
                <div className="container py-5 px-lg-5">
                    <div className="wow fadeInUp" data-wow-delay="0.1s">
                        <p className="section-title text-secondary justify-content-center"><span></span>Our Projects<span></span></p>
                        <h1 className="text-center mb-5">Recently Completed Projects</h1>
                    </div>
                    <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="col-12 text-center">
                            <ul className="list-inline mb-5" id="portfolio-flters">
                                <li className="mx-2 active" data-filter="*">All</li>
                                <li className="mx-2" data-filter=".first">Web Design</li>
                                <li className="mx-2" data-filter=".second">Graphic Design</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row g-4 portfolio-container">

                        {props.data.map((show)=>
                            <div className={`col-lg-4 col-md-6 portfolio-item ${show.filter} wow fadeInUp`} data-wow-delay="0.1s" style={{bottom:"471px!important"}}>
                                <div className="rounded overflow-hidden">
                                    <div className="position-relative overflow-hidden">
                                        <img className="img-fluid w-100" src={show.image} alt="" />
                                            <div className="portfolio-overlay">
                                                <a className="btn btn-square btn-outline-light mx-1" href={show.image} data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                                <Link className="btn btn-square btn-outline-light mx-1" to={show.link}><i className="fa fa-link"></i></Link>
                                            </div>
                                    </div>
                                    <div className="bg-light p-4">
                                        <p className="text-primary fw-medium mb-2">{show.sub_title}</p>
                                        <h5 className="lh-base mb-0">{show.title}</h5>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Project
