import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import Home from "./Component/Home";
import AboutPg from "./Component/pages/AboutPg";
import ServicePg from "./Component/pages/ServicePg";
import ProjectPg from "./Component/pages/ProjectPg";
import OurTeamPg from "./Component/pages/OurTeamPg";
import TestimonialPg from "./Component/pages/TestimonialPg";
import ContactPg from "./Component/pages/ContactPg";

function App() {
  return (
    <>
      <div className="container-xxl bg-white p-0" style={{maxWidth: "100%"}}>
        <BrowserRouter>
        <Header/>
          <Routes>
            <Route path="/contact" element={<ContactPg/>}/>
            <Route path="/testimonial" element={<TestimonialPg/>}/>
            <Route path="/our-team" element={<OurTeamPg/>}/>
            <Route path="/project" element={<ProjectPg/>}/>
            <Route path="/service" element={<ServicePg/>}/>
            <Route path="/about" element={<AboutPg/>} />
            <Route path="/" element={<Home/>} />
          </Routes>
        <Footer/>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
