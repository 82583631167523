import React, { useState } from 'react'
import FormField from './FormField'

function Contact() {
    const[data, setData] = useState({
        name:null,
        email:null,
        subject:null,
        message:null,
    })

    const changeHandler = (event) => {
        setData({...data, [event.target.name]:event.target.value});
    }

    const submitHandler = (event) => {
        event.preventDefault();
        alert(`Name:- ${data.name}, Email:- ${data.email}, Subject:- ${data.subject}, Message:- ${data.message}`);
        setData({
            name:"",
            email:"",
            subject:"",
            message:"",
        })
    }

    return (
        <>
            <div className="container-xxl py-5">
                <div className="container py-5 px-lg-5">
                    <div className="wow fadeInUp" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "0.3s", animationName: "fadeInUp"}}>
                        <p className="section-title text-secondary justify-content-center"><span></span>Contact Us<span></span></p>
                        <h1 className="text-center mb-5">Contact For Any Query</h1>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="wow fadeInUp" data-wow-delay="0.3s" style={{visibility: "visible", animationDelay: "0.3s", animationName: "fadeInUp"}}>
                                <form onSubmit={submitHandler}>
                                    <div className="row g-3">

                                        <FormField eventHandler={changeHandler} col="col-md-6" label="Your Name" type="text" name="name" value={data.name} id="name" />
                                        <FormField eventHandler={changeHandler} col="col-md-6" label="Your Email" type="email" name="email" value={data.email} id="email" />
                                        <FormField eventHandler={changeHandler} col="col-md-12" label="Your Subject" type="subject" name="subject" value={data.subject} id="subject" />
                                        <FormField eventHandler={changeHandler} col="col-md-12" label="Your Message" type="message" name="message" value={data.message} id="message" />

                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
