import React from 'react'
import CommonCover from '../CommonCover'
import Contact from '../Contact'


function ContactPg() {
    return (
        <>
            <CommonCover breadcrumb="Contact" />
            <Contact/>
        </>
    )
}

export default ContactPg
