import React from 'react'
import { Link } from 'react-router-dom'

function Service(props) {
    return (
        <>
            <div className="container-xxl py-5">
            <div className="container py-5 px-lg-5">
                <div className="wow fadeInUp" data-wow-delay="0.1s">
                    <p className="section-title text-secondary justify-content-center"><span></span>Our Services<span></span></p>
                    <h1 className="text-center mb-5">What Solutions We Provide</h1>
                </div>
                <div className="row g-4">

                    {props.data.map((show) =>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item d-flex flex-column text-center rounded">
                                <div className="service-icon flex-shrink-0">    
                                    <i className={show.icon}></i>
                                </div>
                                <h5 className="mb-3">{show.title}</h5>
                                <p className="m-0">{show.para}</p>
                                <Link className="btn btn-square" to={show.link}><i className="fa fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>
        </div>
        </>
    )
}

export default Service
